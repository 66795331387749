




















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { DeviceRelation } from '@/models/data/models';
import { Device } from '@/models/device/models';
import { ListModelField } from '@/models/core/base';
import { globalStore } from '@/store/modules/global';
import ConfigSelector from './ConfigSelector.vue';

@Component({
  components: {
    ConfigSelector,
  },
})
export default class DeviceRelationSelectList extends Vue {
  DeviceRelation = DeviceRelation;
  selection = '';
  showList = false;
  deviceRelation: DeviceRelation = null;
  deviceName = '';

  get filter() {
    return {
      application: globalStore.selection.application?.id || 'unknown',
      order_by: 'device_name_asc',
    };
  }

  get columns() {
    const listFields: ListModelField[] = [
      {
        key: 'device_name',
        sortable: true,
        searchQuery: 'device_name_search',
      },
      {
        key: 'device_device_id',
      },
      {
        key: 'role',
        sortable: true,
      },
      {
        key: 'last_seen',
      },
    ];
    return DeviceRelation.defaultColumns(DeviceRelation.langPath, listFields);
  }

  created() {
    this.selection = this.$routerHandler.query('')['device_relation'];
    if (this.selection) {
      this.updateDeviceName();
    }
  }

  rowSelection(selection: DeviceRelation[]) {
    if (selection.length > 0) {
      this.deviceRelation = selection[0];
      this.selection = this.deviceRelation.id;
      this.$routerHandler.updateQuery('', {
        device_relation: this.deviceRelation.id,
      });
      this.deviceName = this.deviceRelation.role;
      this.updateDeviceName();
      this.showList = false;
      this.$emit('row-selection', selection);
    }
  }

  async updateDeviceName() {
    try {
      if (!this.deviceRelation) {
        this.deviceRelation = await this.$apiv2.get<DeviceRelation>(
          DeviceRelation,
          this.selection,
        );
      }
      const device = await this.$apiv2.get<Device>(
        Device,
        this.deviceRelation.device,
      );
      this.deviceName = device.name;
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }
}
