
























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ClientAppSetting, ClientApp } from '@/models/client/models';
import { globalStore } from '@/store/modules/global';
import { studyStore } from '@/store/modules/study';

@Component({})
export default class ConfigSelector extends Vue {
  // is a selection required
  @Prop({ default: false }) required: boolean;
  // which property of the option to display in the selection
  @Prop({ default: 'name' }) displayProp: string;
  // the key of the data app config where all the options are listed
  @Prop({ required: true }) configKey: string;
  // the query key to be used in the url
  @Prop({ required: true }) queryKey: string;
  // the property of the option to be used for the value
  @Prop({ default: 'id' }) optionProp: string;
  // label for the select input
  @Prop({ required: true }) label: string;
  // whether to disable the select input
  @Prop({ default: false }) disabled: boolean;
  // key of the client app settings
  @Prop({ required: false }) settingKeyProp: string;
  // client app id
  @Prop({ required: false }) clientApp: string;

  options = [];
  option = null;

  @Watch('$route.query')
  onRouteChange() {
    this.getOptionFromRouter();
  }

  async mounted() {
    await this.getOptionsList();
    this.getOption();
  }

  get settingKey(): string {
    return this.settingKeyProp || this.configKey;
  }

  get message(): string {
    if (this.required && !this.option) {
      return 'required';
    } else {
      return '';
    }
  }

  get clientAppId(): string {
    return this.clientApp || globalStore.selection.clientApp.id;
  }

  async getOptionsList() {
    try {
      // this component is used to select an option of a list that is defined in the client app settings
      let setting: ClientAppSetting;
      if (
        this.clientApp &&
        this.clientApp ===
          globalStore.clientAppSetting('data_application')?.value
            ?.data_client_app_id
      ) {
        // we are in study app and need to get from data app
        setting = studyStore.dataAppSetting(this.settingKey);
      } else {
        // try getting from store of current client app
        setting = globalStore.clientAppSetting(this.settingKey);
        if (!setting) {
          // get from backend
          setting = await ClientApp.getSetting(
            this.$apiv2,
            this.clientAppId,
            this.settingKey,
          );
        }
      }
      this.options = setting?.value?.[this.configKey] || [];
    } catch (error) {
      console.log(error);
      this.options = [];
    }
  }

  getOption() {
    // first priority is url query
    this.getOptionFromRouter();
    if (!this.option) {
      // if only 1 option and required, select this option
      if (this.required && this.options.length === 1) {
        this.option = this.options[0];
      }
    }
    // if any found, set it
    if (this.option) {
      this.setOptionToRouter(this.option);
    }
  }

  getOptionFromRouter() {
    // set option from url
    const queryValue = this.$routerHandler.query('')[this.queryKey];
    this.option = this.options.find(option => {
      return option[this.optionProp] === queryValue;
    });
  }

  setOptionToRouter(option) {
    if (option) {
      this.$routerHandler.updateQuery('', {
        [this.queryKey]: (option && option[this.optionProp]) || '',
        page: '1',
      });
    } else {
      this.$routerHandler.removeFromQuery('', this.queryKey);
    }
  }
}
